
@font-face {
	font-family: 'HelveticaNowText';
	src: 	url('HelveticaNowText-Regular.eot?#iefix') format('embedded-opentype'),
			url('HelveticaNowText-Regular.woff') format('woff'), 
			url('HelveticaNowText-Regular.ttf') format('truetype'), 
			url('HelveticaNowText-Regular.svg#HelveticaNowText-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaNowText';
	src: 	url('HelveticaNowText-Light.eot?#iefix') format('embedded-opentype'),
			url('HelveticaNowText-Light.woff') format('woff'), 
			url('HelveticaNowText-Light.ttf') format('truetype'), 
			url('HelveticaNowText-Light.svg#HelveticaNowText-Light') format('svg');
	font-weight: 300;
}

@font-face {
	font-family: 'HelveticaNowText';
	src: 	url('HelveticaNowText-Bold.eot?#iefix') format('embedded-opentype'),
			url('HelveticaNowText-Bold.woff') format('woff'), 
			url('HelveticaNowText-Bold.ttf') format('truetype'), 
			url('HelveticaNowText-Bold.svg#HelveticaNowText-Bold') format('svg');
  font-weight: 700;
}