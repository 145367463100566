@import '../../assets/sass/abstract';

.pricing-tables {
    margin: 120px 0 60px;
    .row {
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.2s ease 0.4s;
        @media (max-width: $tablet) {
            margin-left: 0;
            margin-right: 0;
        }
        &:hover {
            .pricing-table.active {
                transform: scale(1);
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
                footer {
                    max-height: 0px;
                    opacity: 0;
                }
                &:hover {
                    transform: scale(1.2);
                    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
                    z-index: 99;
                    footer {
                        max-height: 100px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.pricing-table {
    padding: 30px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
    background-color: $white;
    transition: all 0.4s ease;

    header {
        figure {
            position: relative;
            display: inline-block;

            svg {
                width: 150px;
            }

            img {
                position: absolute;
                top: 45%;
                left: 50%;
                color: $white;
                font-size: 36px;
                transform: translate(-50%, -50%);
                max-width: 40px;
            }
        }
    }

    .table-body {
        display: inline-block;
        min-width: 120px;

        ul {
            li {
                padding: 10px 20px;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 80, from(rgba(0, 0, 0, 0.2)), to(#fff));
                }
            }
        }
    }

    footer {
        max-height: 0px;
        opacity: 0;
        transition: all 0.4s ease;

        .btn {
            font-size: 0.8rem;
            margin: 0 5px;
            padding: 5px 18px;
        }
    }

    &.active,
    &:hover {
        transform: scale(1.2);
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
        z-index: 99;
        @media (max-width: $tablet) {
            transform: scale(1);
        }

        & footer {
            max-height: 100px;
            opacity: 1;
        }
    }
}
