@import '../../assets/sass/abstract';

#site-footer {
    background: $dark;
    color: $grey;
    padding: 60px 0 0;
    @media (max-width: $tablet) {
        padding: 40px 0;
    }

    @media (max-width:  576px) {
        padding-bottom: 0;
    }

    h5,
    b {
        color: $white;
    }

    a:hover {
        color: $white;
    }

    .footer-row {
        border-bottom: solid 1px rgba(255, 255, 255, 0.07);
        padding: 10px;
        padding: 0;
        @media (max-width: $tablet) {
            flex-direction: column;
            padding-bottom: 10px;
            .site-menu .navbar-nav {
                flex-direction: row;
            }
            & > * {
                margin-bottom: 30px;
            }
        }
    }

    .navbar-brand {
        width: 60px;
        display: block;

        img {
            width: 60px;
        }

        @media (max-width: 576px) {
            margin-right: 0;
        }
    }

    .navbar-nav {
        //text-transform: uppercase;

        li {
            margin: 0 10px;

            @media (max-width: 576px) {
                text-align: center;
            }  
        }
    }

    .social-networks {
        color: $white;

        li {
            margin: 0 10px;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                font-size: 20px;
            }
        }
    }

    .footer-info {
        text-align: center;
        display: flex;
        justify-content: space-between;
        @media (max-width: $tablet) {
            margin: 40px 0;
            justify-content: center;
        }

        h5 {
            font-size: 1.13rem;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 10px;
        }

        .details {
            margin-bottom: 30px;
        }

        .info-box-section {
            img {
                width: 12%;
                margin: 0 20px;

                @media (max-width:  576px) {
                    width: 30%;
                }
            }         
        }

        .info-box {
            flex-grow: 1;
            max-width: 100%;
            margin-bottom: 20px;
            @media (max-width: $tablet) {
                max-width: 100%;
                text-align: center;
            }

            &.double-size {
                flex-grow: 2;
                display: flex;
                max-width: 50%;
                @media (max-width: $tablet) {
                    max-width: 100%;
                    flex-direction: column;
                }

                p {
                    line-height: 1.8;
                }

                figure {
                    margin-left: 80px;
                    @media (max-width: $tablet) {
                        margin-left: 0;
                        margin-top: 20px;
                    }

                    img {
                        width: 100px;
                    }
                }
            }
        }
    }

    .footer-copyright {
        display: flex;
        margin: 0;
        justify-content: space-between;
        border-bottom: none;
        padding: 15px 0;
        font-size: 12px;

        @media (max-width: $tablet) {
            justify-content: center;
            text-align: center;
        }

        .copyright-box {
            display: flex;
            align-items: center;
            @media (max-width: $tablet) {
                justify-content: center;
                flex-direction: column-reverse;
            }

            p {
                margin-bottom: 0;
                @media (max-width: $tablet) {
                    span,
                    b {
                        display: block;
                    }
                }
            }

            img {
                width: 30px;
                margin-left: 10px;
                @media (max-width: $tablet) {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
