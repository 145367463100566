@import '../../assets/fonts/HelveticaNowText/styles.scss';
@import '../../assets/sass/abstract';

.title {
    text-align: center;
    margin-top: 115px;

    .exhibitions-title{
        font-size: 36px;
        
        h4.text-exhibitions {
            font-size: 21px;
            color: #808080; 
        }
    } 
}

.previous-exhibitions {
    text-align: center;
    margin-top: 70px;
    h3.previous {
        font-family: 'HelveticaNowText';
        font-size: 24px;
        margin-bottom: 40px; 
        font-weight: 400;
        color: #151515
    }
}


.post-blog-exhibitions {
    border-top: 1px solid  $lightGrey;
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
    // overwrite subtitle color
    & .subtitle {
        color: $white;
    }

    .media-widgets {
        display: flex;
        justify-content: space-between;
        text-align: left;

        .widget {
            width: 270px;
            max-width: 25%;
            overflow: hidden;

            header {
                margin-bottom: 30px;
            }

            article {
                figure {
                    img,
                    & > div {
                        width: 100%;
                        height: auto;
                        border-radius: 2px;
                    }
                }

                h3 {
                    font-size: 1.3em;
                }

                footer {
                    span {
                        display: inline-block;

                        &:first-child:after {
                            content: '';
                            margin: 0 10px;
                            background: $white;
                            width: 4px;
                            height: 4px;
                            display: inline-block;
                            border-radius: 50%;
                            vertical-align: middle;
                        }
                    }
                }

                &.exhibition {
					border-radius: 2px;
					position: relative;
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
					background-color: $dark;
					margin-bottom: 30px;
					min-height: 100px;
					display: flex;
					justify-content: center;
					align-items: center;
                    .exhibition-meta {
						position: relative;
						z-index: 2;
                        padding: 20px;
                        display: flex;
                        align-items: center;
                        .date {
                            text-align: center;
							margin-right: 20px;
							display: flex;
							flex-direction: column;
                            .day {
                                font-size: 2em;
                                line-height: normal;
                                &:after {
                                    content: none;
                                }
                            }
                        }
                        h3 {
                            font-size: 1rem;
                            margin: 0;
                        }
					}
					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background: rgba(21, 21, 21, .35);
					}
				}
				&.latest-news {
					figure img {
						min-height: 180px;
						object-fit: cover;
					}
					.news-meta {
						.category {
							& b:not(:last-child)::after {
								content: ',';
								margin-right: 5px;
							}
						}
					}
				}
            }
        }
    }
}



.button-load-more {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 100px;
	button.button-load-more-post {
		width: 140px;
		height: 40px;
		border: none;
		background-color: white;
		box-shadow: 0 3px 5px 0 rgba(0,0,0,.1);
		cursor: pointer;
	}
}


@media (max-width: 768px) {
    .post-blog-exhibitions {
        flex-direction: column;
        text-align: center;
	}
	.post-blog-exhibitions .calendar {
		margin: auto;
		padding-top: 15px;
	}
}