@import '../../assets/sass/abstract/';

.nav-tabs {
    border-top: 1px solid $lighterGrey;
    border-bottom: 1px solid $lighterGrey;
    background-color: #fff;
    justify-content: center;

    .nav-link {
        border: none;
        font-size: 1.25em;
        color: #808080;

        &.active {
            color: #000;
        }
    }
}

.tab-content {
    background-color: #fff;
    padding: 25px 0;
}

.artist-details-list {
    list-style: none;
    padding: 0;

    li {
        margin: 10px 0;
    }

    p {
        text-transform: capitalize;
    }
}

.hide {
    display: none;
}

.show {
    display: block;
    margin: 0 auto;
}