@import '../../assets/sass/abstract/';

.search-results {
    .search-results-header {
        display: flex;
        justify-content: space-between;
        padding: 40px 0 0px;

        @media (max-width: $tablet) {
            flex-direction: column;
            padding: 0 0 30px;
        }

        & > div {
            width: 20.33%;
            
            @media (max-width: $tablet) {
                width: 100%;
                margin-bottom: 20px;
                justify-content: center !important;
            }
        }

        .results-title {
            text-align: center;
        }

        .search-sort {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            span {
                line-height: 2;
            }

            .css-2b097c-container {
                width: 150px;
                margin-left: 15px;
                .css-yk16xz-control {
                    border-width: 1px;
                }
            }
        }
    }
    .artist-post {
        display: flex;
        padding: 60px 0;
        border-style: solid;
        border-color: $lightGrey;
        border-width: 0 0 1px 0;
        &:last-child {
            border: none;
        }
        @media (max-width: $tablet) {
            flex-direction: column;
            width: 100%;
        }
        figure {
            margin: 0;
            @media (max-width: $tablet) {
                text-align: center;
                margin-bottom: 20px;
            }
            img {
                width: 300px;
                height: 200px;
                object-fit: cover;
            }
        }
        .artist-content {
            flex-grow: 1;
            margin-left: 30px;
            @media (max-width: $tablet) {
                margin-left: 0;
            }
            header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                ul {
                    li {
                        display: inline-block;
                        margin-left: 20px;
                        @media (max-width: $tablet) {
                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
                @media (max-width: $tablet) {
                    flex-direction: column;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
            .artist-details {
                display: flex;
                justify-content: space-between;
                margin: 0 -15px;
                @media (max-width: $tablet) {
                    flex-direction: column;
                    margin: 0 15px;
                }
                .details-column {
                    flex: 1 1 0;
                    margin: 0 15px;
                    border-style: solid;
                    border-color: $lightGrey;
                    border-width: 1px 0;
                    @media (max-width: $tablet) {
                        border-top-width: 0;
                    }
                    .details-cell {
                        text-align: center;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 50px;
                        margin: 0;
                        &:first-child {
                            border-bottom: solid 1px $lightGrey;
                        }

                        a {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            padding: 0 15px;
                            line-height: 50px;
                            &:hover {
                                text-decoration: none;
                                background-color: $lightGrey;
                            }
                        }

                        button {
                            padding: 0;
                            line-height: 50px;
                            padding: 0 15px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            &:hover {
                                background-color: $lightGrey;
                            }
                        }

                        dt {
                            font-weight: normal;
                        }
                        dd {
                            font-weight: bold;
                        }
                    }
                    &.table-view {
                        border-width: 1px;
                        display: flex;
                        flex-wrap: wrap;
                        flex: 1;
                        @media (max-width: $tablet) {
                            margin-bottom: 30px;
                        }
                        .details-cell {
                            &:first-child {
                                width: 100%;
                            }
                            &:nth-child(2) {
                                border-style: solid;
                                border-color: transparent;
                                border-width: 0 1px 0 0;
                                flex: initial;
                                @media (max-width: $tablet) {
                                    width: 100%;
                                    border: 0;
                                }
                            }
                            &:nth-child(3) {
                                flex: 1;
                            }
                            i {
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    .results-title {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        @media (max-width: $tablet) {
            flex-direction: column;
        }
        .page-title {
            font-size: 1.5rem;
        }
        .search-term {
            position: absolute;
            top: 100%;
            left: 0;
            max-width: 100%;
            white-space: normal;
            word-break: break-all;
            right: 0;
            text-align: center;
            font-size: 1rem;
            color: $grey;
            @media (max-width: $tablet) {
                position: relative;
            }
        }
    }
    .results-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
            margin-bottom: 0;
        }
    }

    .lightbox-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1990;
        transition: all 0.4s ease;
    }

    .account-lightbox {
        position: fixed;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 440px;
        z-index: 200000;
        padding: 40px;
        background-color: #fff;
        border-radius: 7px;
        transition: all 0.4s ease;

        .motto-text {
            margin: 30px 0;
        }
        
        .databaza-logo {
            width: 70px;
            display: block;
            margin: 0 auto;
        }

        .close-lightbox {
            position: absolute;
            top: -23px;
            right: -23px;
            cursor: pointer;
    
            i {
                font-size: 1em;
            }
        }

        .lightbox-primary-button {
            background-color: #000;
            border: 2px solid #000;
            color: #fff;
            margin: 0 10px;
        }

        .lightbox-secondary-button {
            background-color: #fff;
            border: 2px solid #000;
            margin: 0 10px;
        }

        .lightbox-buttons-group {
            text-align: center;
        }

        @media (max-width: $tablet) {
            button,
            a {
                display: inline-block;
                margin: 20px 20px 0 20px !important;
            }
        }
    }
}

.no-results {
    font-weight: bold;
    background: $lightGrey;
    padding: 10px 20px;
    border-radius: 5px;
}

.dd-wrapper {
    display: flex;
    margin-left: 20px;
    max-width: calc(100% - 20px);
    overflow-y: auto;
    dd {
        margin-bottom: 0;
        white-space: nowrap;
        & + dd {
            margin-left: 15px;
        }
    }
}

.x-close-button {
    position: relative;
}

.x-close-button:hover {
    background-color: #515151;
}

.show-blog-options {
    display: flex;
    align-items: center;
    margin-top: 10px;

    @media (max-width: 576px) {
        justify-content: center;
    }
}

.select-blog-options-parent {
    width: 200px;
    margin-left: 20px;
}

.blog-icons {
    display: flex;
    @media (max-width: 576px) {
        justify-content: center;
        padding: 0;
    }
    .blog-icon-parent {
        margin: 0 6px;
        border: solid 2px black;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.bg_white {
            background-color: $white;
            color: $pink;
            border-color: $pink;
        }

        &.bg_pink {
            background-color: $pink;
            color: $white;
            border-color: $pink;
        }
    }
}

.filtered-parent {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 15px;
    margin: 40px 0px;
}
@media (max-width: 576px) { 
    .filtered-parent{
        display: block;
    }
 }

