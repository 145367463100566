
.tabs-container {
    text-align: center;
    border: 2px solid #f1f1f1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    * {
        color: #a7a9ac;
        cursor: pointer;
    }
    .active-col { 
        * {
            color: black !important;
        }
    }
}

.saved-artist-container {
    max-width: 750px;
    i {
        cursor: pointer;
    }
}
.remove-artist-wrapper {
    position: relative;
    display: flex;
    justify-content: end;
    span {
        position: absolute;
        color: white;
        z-index: 2;
        top: 1px;
        right: 5px;
    }
}
.saved-artists-header {
    display: flex;
    justify-content: space-between;
}
.saved-artist-type {
    color: #a7a9ac;
}
.saved-artist-image {
    width: 100%;
    min-height: 132px;
    object-fit: cover;
    margin-bottom: 5px;
}

.save-changes-btn {
    background-color: transparent;
    width: 100%;
    padding: 15px;
    text-align: center;
    border: 2px solid black;
    color: black;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
}