@import '../../assets//sass/abstract/';

ul.alerts-container {
    position: fixed;
    top: 100px;
    right: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 2000;
    li {
        padding: 20px 30px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        border-radius: 10px 0 0 10px;
        max-width: 500px;
        color: $white;
        box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
        transition: all 0.4s ease;
        //transform: translateX(101%);
        &.error {
            background-color: $dark;
            & .close {
                color: $white;
            }
        }
        &.success {
            background-color: $lightGrey;
            color: $text;
            & .close {
                color: $dark;
            }
        }
        &.active {
            transform: translateX(0);
        }
        .close {
            margin-left: 30px;
            font-size: 20px;
        }
    }
}
