article.artwork {
    margin-bottom: 30px;
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
}
.artworks-section {
    .col-md-3 {
        margin: 0 auto;
    }
}