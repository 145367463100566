@import '../../assets/sass/abstract';

.media {
    background-color: $pink;
    color: $white;

    // overwrite subtitle color
    & .subtitle {
        color: $white;
    }

    .media-widgets {
        display: flex;
        justify-content: space-between;
        text-align: left;

        .widget {
            width: 270px;
            max-width: 25%;
            overflow: hidden;
            display: block;
            margin: 0 auto;

            header {
                margin-bottom: 30px;
                text-align: center;

                h4 {
                    font-weight: bold;
                }
            }

            article {
                a {
                    font-size: 1.2em;
                    line-height: 1;
                    overflow: hidden;
                    width: 100%;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                figure {
                    img,
                    & > div {
                        width: 100%;
                        height: auto;
                        border-radius: 2px;
                    }
                }

                h3 {
                    font-size: 1.3em;
                }

                footer {
                    span {
                        display: inline-block;

                        &:first-child:after {
                            content: '';
                            margin: 0 10px;
                            background: $white;
                            width: 4px;
                            height: 4px;
                            display: inline-block;
                            border-radius: 50%;
                            vertical-align: middle;
                        }
                    }
                }

                &.exhibition {
					position: relative;
					border-radius: 2px;
					position: relative;
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
					background-color: $dark;
					margin-bottom: 30px;
					min-height: 100px;
					display: flex;
					overflow: hidden;
					justify-content: center;
					align-items: center;
                    .exhibition-meta {
                        padding: 20px;
                        display: flex;
                        align-items: center;

                        .date {
                            text-align: center;
							margin-right: 20px;
							display: flex;
							flex-direction: column;

                            .day {
                                font-size: 2em;
                                line-height: normal;

                                &:after {
                                    content: none;
                                }
                            }
                        }

                        h3 {
                            font-size: 1rem;
                            margin: 0;
                        }
                    }
				}
				&.latest-news {
					figure img {
						min-height: 180px;
						object-fit: cover;
					}
					.news-meta {
						.category {
							& b:not(:last-child)::after {
								content: ',';
								margin-right: 5px;
							}
						}
					}
				}
            }

            @media (max-width:  576px) {
                max-width: 100%;
            }
        }
    }
}


.search-filter-row {
    box-shadow: none;
}


.slick-next {
    right: 5px !important;
}

.slick-prev {
    left: 5px !important;
}

.search-term {
    min-height: 24px;
}