@import '../../assets/sass/abstract';

.form-section {
    padding: 40px 50px 30px;
    border-bottom: solid 1px $lightGrey;
    @media (max-width: $tablet) {
        padding: 30px 15px;
    }
    &.info-section {
        .row {
            [class^='col'] + [class^='col'] {
                text-align: right;
            }
            @media (max-width: $tablet) {
                text-align: center;
                [class^='col'] + [class^='col'] {
                    text-align: center;
                }
            }
        }
    }
    &.no-border {
        border: none;
    }
    &.submit-section {
        padding: 0;
        transform: translateY(50%);
        @media (max-width: $tablet) {
            margin-bottom: 40px;
        }
    }
}

.art-works-checkboxes {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-evenly;
}

.paper-view {
    border-radius: 0 0 10px 10px;
    border: 1px solid $lighterGrey;
    box-shadow: none;
}

.paper-view-header {
    background-color: #f6f6f6;
    .col-md-6 {
        &:first-child {
            position: relative;
            border-right: solid 1px $lightGrey;
            @media (max-width: $tablet) {
                border-right: none;
                &::after {
                    content: '';
                    position: absolute;
                    display: block;
                    left: 15px;
                    right: 15px;
                    height: 1px;
                    background-color: $lightGrey;
                }
            }
        }
    }

    a {
        font-size: 2em;
        line-height: 3;
        color: $grey;
        text-decoration: none;
        &.active-tab {
            color: $pink;
            background-color: $white;
        }
    }
}

.form-group {
    @media (max-width: $tablet) {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 1.5rem;
        .form-text {
            order: -1;
        }
    }
    h4 {
        margin-bottom: 30px;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

    .react-datepicker-wrapper input:focus {
        box-shadow: none !important;
        border: 1px solid $pink !important;
    }
    
    .react-tagsinput--focused {
        border-color: $pink !important;
    }
}

.artist-profile-section,
.artists-art-work {
    background-color: $pink;
    color: $white;
    border: solid 1px $pink;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $green;
    background-color: $green;
}

.btn-add {
    background-color: $lighterGrey;
    
    i {
        margin-right: 10px;
    }
}