@import '../../assets/sass/abstract';

.how-it-works {
    padding-bottom: 120px;
    @media (max-width: $tablet) {
        padding-bottom: 40px;
    }
}

.service {
    display: flex;
    position: relative;
    margin: 15px 0;
    @media (max-width: $tablet) {
        flex-direction: column;
        width: 100%;
        text-align: center;
        justify-content: center;
        padding: 0 80px;
    }

    .service-title {
        font-weight: bold;
    }

    .service-content {
        margin-left: 20px;
        max-width: calc(100% - 120px);
        @media (max-width: $tablet) {
            margin-left: auto;
            max-width: 100%;
        }
        @media (max-width: 576px) {
            margin-top: -40px;
        }
    }

    .arrow {
        position: absolute;
        left: 70px;

        &.top {
            top: -90px;
        }

        &.bottom {
            top: auto;
            bottom: -40px;
            transform: rotate(180deg) matrix(-1, 0, 0, 1, 0, 0);
        }

        @media (max-width: $tablet) {
            display: none;
        }
    }
    &.alternative-layout {
        display: block;
        padding: 0 30px;

        @media (max-width: 576px) {
            padding: 0 25px;
        }

        figure {
            margin: 0 auto 20px;
            border: 0;
        }
        .service-content {
            max-width: 100%;
            margin-left: 0;
            text-align: center;
            .service-title {
                margin-bottom: 20px;
                min-height: 56px;
            }
            ul {
                list-style: circle;
                list-style-position: inside;
            }
        }
    }
}
