$dark: #151515;
$grey: #808080;
//$lightGrey: #f7f7f7;
$lightGrey: #efefef;
$lighterGrey: #f4f4f4;
$white: white;
$heading: $dark;
$text: $grey;
$highlight: #064684;
$pink: #f05586;
$red: #900c3e;
$green: #3aad49;

$tablet: 767px;
$laptop: 992px;
