@import '../../assets//sass/abstract/';

.under-construction {
    color: #1f1f1f;
    width: 700px;
    max-width: 100%;
    padding: 30px;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
        margin-bottom: 30px;
    }
    img {
        width: 200px;
        margin-bottom: 30px;
    }
}