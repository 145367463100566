.lightbox-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1990;
    transition: all 0.4s ease;
}

.lightbox-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 440px;
    z-index: 200000;
    padding: 40px;
    background-color: #fff;
    border-radius: 7px;

    .motto-text {
        margin: 30px 0;
    }
    
    .databaza-logo {
        width: 70px;
        display: block;
        margin: 0 auto;
    }

    .close-lightbox {
        position: absolute;
        top: -23px;
        right: -23px;
        cursor: pointer;

        i {
            font-size: 1em;
        }
    }

    .lightbox-primary-button {
        background-color: #000;
        border: 2px solid #000;
        color: #fff;
        margin: 0 10px;
    }

    .lightbox-secondary-button {
        background-color: #fff;
        border: 2px solid #000;
        margin: 0 10px;
    }

    .lightbox-buttons-group {
        text-align: center;
    }

    @media (max-width: $tablet) {
        button,
        a {
            display: inline-block;
            margin: 0 auto !important;
        }
    }

    @media (max-width:  576px) {
        width: 310px;
        text-align: center;
    }
}

section.media,
.pricing-section {
    // display: none;
}

.next-exhibitions-text {
    text-align: center;
    font-size: 2.3rem;
    line-height: 1.2;
    font-weight: bold;
}

html section.contact-page-section {
    @media (max-width: $tablet) {
        padding-top: 60px;
    }
}

.danger-zone {
    background-color: $lightGrey;
    padding: 30px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.terms-and-conditions-section {
    text-align: justify;
    .part {
        margin-bottom: 60px;
    }
    h4 {
        margin-bottom: 20px;
    }
}

.faq-part {
    margin: 25px 0 45px 0;
}

.forgot-password-link {
    margin-bottom: 20px;
    text-align: right;
    display: block;
}

.custom-post-categories {
	b::after {
		content: ',';
		margin-right: 5px;
	}
	b:last-child::after {
		content: none;
	}
}

.calendar-wrapper {
	display: flex;
	align-items: center;
	.calendar {
		width: 70px;
		margin-right: 30px;
		& > div {
			background-color: $lightGrey;
			text-align: center;
		}
		p {
			margin: 0;
		}
		.data-nr {
			font-size: 36px;
			margin-bottom: 1px;
			padding: 14px 0;
		}
		.month {
			text-transform: uppercase;
			padding: 8px 0;
			font-size: 18px;
		}
	}
}
.img-post-exhibitions .img-post {
	height: 125px;
	object-fit: cover;
	width: 100%;
}
.text-title-exhibitions {
	font-size: 24px;
	font-weight: bold;
	color: $grey;
}
.all-art > .row,
.post-blog-exhibitions > .row {
	width: 100%;
}

.has-html {
	margin-bottom: 90px;
	img {
		width: auto;
		height: auto;
	}
}
.single-page-header {
	display: flex;
	justify-content: space-between;
}

.registration-complete .databaza-logo {
    width: 100px;
}