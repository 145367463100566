@import '../../assets/fonts/HelveticaNowText/styles.scss';
.navlist li
{
display: inline;
color: black;
cursor: pointer;
  font-weight: bold;
    .navlist li:hover{
      color: black;
      .navlist li a
    {
    text-decoration: none;
    }
    }
    
    
}
.navlist .icon{
float: right;
margin: 0;

}
ul.navlist {
  padding: 0;
  margin-bottom: 25px;
}




p.text-black-girl {
    display: inline;
	cursor: pointer;
  font-size: 18px;
  p.text-black-girl {
    display: inline;
}

}





.img-back{
  margin-bottom: 50px;
}
/* section-2 */

img.artist-img {
	width: 100%;
    height: 360px;
    object-fit: cover;
}

.box-1 {
    position: absolute;
    left: 10%;
    bottom: 0%;
    background-color: #F2F2F2;
    width: 70px;
    text-align: center;
    border-bottom: 2px solid white;
    height: 60px;
    .data-nr {
      font-size: 36px;
  }
}
.box-2 {
	position: absolute;
    left: 10%;
    bottom: -24%;
    background-color: #F2F2F2;
    width: 70px;
    text-align: center;
    height: 40px;
    .month {
      font-size: 18px;
      padding-top: 7px;
    }
}







.text-title-and-author{
  margin-bottom: 35px;
}



// section -2
.singlemedia p{
	font-family: 'HelveticaNowText';
  line-height: 32px;
  color: #808080;
  margin-bottom: 50px;
  font-size: 15px;
  }
.singlemedia h4 {
font-family:"HelveticaNowText-Bold";
font-size: 24px;
margin-bottom: 30px;
}


.go-to-event {
  text-align: center;
  margin-top: 200px;
  margin-bottom: 100px;
  button.button-go-event {
    border: none;
  background-color: white;
  cursor: pointer;
  font-size: 16px;
  color: #808080;
  
  }
}
@media (max-width: 768px) {
	img {
      width: 100%;
    }
}



// .text-galleries{
// 	font-size: 16px;
// 	font-family: 'HelveticaNowText';
// 	line-height: 32px;
// 	color: #808080;
//   }
  