@import '../../assets/sass/abstract/';

.artist-header {
    text-align: center;
    .cover-image {
        height: 250px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .artist-header-content {
        margin-top: -76px;
        .profile-image {
            background-color: $white;
            padding: 8px;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            overflow: hidden;
            display: inline-block;
            img {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &.user-border {
                background-color: $red;
            }
        }
        .header-links {
            display: flex;
            justify-content: space-between;
            margin-top: -50px;

            @media (max-width: 576px) {
                margin-top: 0;
            }
            .artist-breadcrumps {
                display: flex;
                li {
                    margin-right: 15px;
                    &:not(:last-child) {
                        &::after {
                            content: '\f531';
                            margin-left: 15px;
                            font-family: 'Font Awesome 5 Pro';
                        }
                    }
                }
            }
            .artist-meta {
                display: flex;
                margin-bottom: 1rem;
                .link-group {
                    position: relative;
                    &:not(:last-child) {
                        margin-right: 30px;
                        &::after {
                            content: '.';
                            margin-left: 15px;
                            position: absolute;
                            top: -2px;
                            right: -15px;
                        }
                    }
                    .social-networks {
                        display: flex;
                        flex-direction: row;
                        li:not(:last-child) {
                            margin-right: 10px;
                        }
                    }
                    .btn i {
                        margin-left: 10px;
                    }
                    a i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

.artwork {
    .artist-meta {
        color: grey;
        span + span {
            margin-left: 20px;
            position: relative;
            &::before {
                font-family: "Font Awesome 5 Pro";
                content: "\f111";
                display: block;
                font-size: 3px;
                position: absolute;
                top: 5px;
                left: -14px;
                color: $grey;
                font-weight: 900;
            }
        }
    }
}

.share-socials {
    position: fixed;
    top: 175px;
    left: 40px;
    color: #000;

    .share-socials-title {
        padding-left: 20px;
        text-transform: uppercase;
    }

    .display-none {
        display: none;
    }
  
    ul {
      list-style: none;
      text-align: center;
      padding-left: 20px;
  
      li:not(:first-child) {
          background: black;
          border-radius: 100%;
          width: 27px;
          height: 27px;
          color: white;
          padding: 2px;
          display: block;
          margin: 5px auto;
      }
    }
}


@media (max-width: 576px) {
    .mobile-view {
        bottom: 0;
        top: 94vh;
        left: 0;
        background-color: #fff;
        width: 100%;
        text-align: center;
        border-top: 1px solid $lightGrey;
        
        h6 {
            display: none;
        }

        ul {
            display: inline-block;
            padding-left: 0;
        }
    }
}

