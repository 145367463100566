@import '../../assets/sass/abstract/variables.scss';

.contact-form-submit {
    margin-top: 30px;
}

.css-18fcis9-Input input:focus {
    border-color: transparent;
    box-shadow: none;
}

input:focus.form-control,
textarea:focus.form-control {
    border: 1px solid $pink !important;
}

.green-border {
    border: 1px solid $green !important;
    border-radius: 0.25rem;
}

.form-label {
    span {
        &.required-field::after {
            color: $pink !important;
            top: 15px !important;
            left: 32px !important;
        }
    }
}

.css-kiysmz-control {
    border: 1px solid #efefef !important;
}

.css-1xia1zz-control {
    box-shadow: none !important;
    border: 1px solid #efefef !important;
}

.css-kiysmz-control:hover,
.css-1xia1zz-control:hover,
.css-1xia1zz-control:focus {
    border-color: $pink !important;
    box-shadow: none;
}