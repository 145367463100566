@import '../../assets/fonts/HelveticaNowText/styles.scss';
@import '../../assets/sass/abstract/';

.navlist {
    margin-bottom: 10px !important;

    li {
        display: inline;
        color: black;
        cursor: pointer;
        font-weight: 400 !important;
        .navlist li:hover {
            color: black;
        }
        .navlist .icon {
            float: right;
            .navlist li a {
                text-decoration: none;
                ul.navlist {
                    padding: 0;
                    padding-left: 14px;
                }
            }
        }
    }
}

.required-field-newsletter {
    color: red;
    position: relative;
    top: 5px;
    font-size: 14.5px;
}

.image-below-text {
    font-weight: normal;
    list-style-type: none;
    text-align: left;
    margin: 12px 0;
    color: grey;
    font-size: 14px;
}

.image-below-title {
    font-weight: normal;
    list-style-type: none;
    text-align: left;
    margin: 0;
    color: black;
}

.image-below-subtitle {
    font-weight: normal;
    text-align: left;
    margin: 12px 0;
    color: grey;
}

.media-news.col-md-10.offset-md-1 {
    margin-top: 60px;
}

/* section-2 */
img {
    width: 100%;
}
.img-news-back {
    margin-bottom: 50px;
}

.singlenewspage h4 {
    font-size: 24px;
    margin-bottom: 36px;
}

.box-1-single {
    margin-bottom: 45px;
}
.single-box {
    width: 55px;
    margin-bottom: 1px;
}
.data-nr-event {
    font-size: 36px;
}
.month-event {
    font-size: 18px;
    padding-top: 7px;
}
.border-single {
    border-top: 1px solid white;
}
img.img-news-back-single {
    height: 500px; // 400px;
    object-fit: cover;
    width: 100%;
    margin-bottom: 25px;
}
.has-html > p {
    padding: 0;
    margin: 0;
}
li.fal.fa-angle-right {
    margin: 7px;
}
.grid-blog {
    margin-left: 40px;
    margin-top: -124px;
    position: absolute;
}

.title-header-blog {
    color: red;
}

.social-network-blog-parent {
    display: flex;
    justify-content: center;
    margin: 20px;
    margin: 5px;
}
.social-network-link {
    margin: 0 10px;
    border: solid 2px black;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.share-socials {
  position: fixed;
  top: 175px;
  color: #000;

  .share-socials-title {
      padding-left: 20px;
      text-transform: uppercase;
  }

  ul {
    list-style: none;
    text-align: center;
    padding-left: 20px;

    li:not(:first-child) {
        background: black;
        border-radius: 100%;
        width: 27px;
        height: 27px;
        color: white;
        padding: 2px;
        display: block;
        margin: 5px auto;
    }
  }
}

.newsletter-subscribe {
    border-top: 1px solid $lightGrey;
    padding: 25px;

    .form-group .form-control,
    .btn-dark {
        height: 50px;
    }

    .form-group .form-label {
        height: 55px;
    }

    .newsletter-subscribe-title {
        margin-bottom: 2rem;
    }
}

@media (max-width: 576px) {
    .mobile-view {
        bottom: 0;
        top: 94vh;
        left: 0;
        background-color: #fff;
        width: 100%;
        text-align: center;
        border-top: 1px solid $lightGrey;

        h6 {
            display: none;
        }

        ul {
            display: inline-block;
            padding-left: 0;
        }
    }
}