@import '../../assets/sass/abstract/';

.about-section {
    @media (max-width: $tablet) {
        padding: 30px 30px 0;
        .container {
            &::after {
                bottom: 0 !important;
            }
        }
    }
    &:not(.background-clear) {
        background-color: $lighterGrey;
    }
    .about-logo {
        width: 78px;
        margin-bottom: 40px;
    }

    p {
        margin-bottom: 40px;
    }
    
    .title-wrapper {
        margin-bottom: 40px;
    }

    .btn {
        background-color: #f9f9f9;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    }

    @media (max-width: $tablet) {
        .btn {
            margin-bottom: 40px;
        }
    }
}

.about-page-description {
    @media (max-width: $tablet) {
        padding: 60px 30px 40px;
        .title-wrapper {
            margin-bottom: 40px;
        }
    }
    ul {
        text-align: left;
        margin-bottom: 30px;
        li {
            margin-bottom: 10px;
        }
    }
}

.why-do-we-exist {
    background-color: $lighterGrey;
}

.our-purpose {
    background-color: $pink;
    color: #ffffff;

    .title-wrapper {
        .subtitle {
            color: #ffffff;
        }
    }

    .about-paragraph {
        text-align: center;
    }
}

.about-image {
    margin: 30px auto;
    text-align: center;
    img {
        max-width: 150px;
    }
}

.purpose-list {
    list-style: none;

    .purpose-list-item {
        margin-bottom: 25px;
    }
}

section.sponsors {
    padding-left: 30px;
    padding-right: 30px;
}

.bold {
    font-weight: 600;
}