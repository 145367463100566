@import '../../assets/sass/abstract/';

.simple-search {
    position: relative;
    border-bottom: 1px solid #e6e6e6;

    form {
        input {
            &:not(#react-select-2-input) {
                box-shadow: none;
            }
            width: 100%;
            line-height: 5.3;
            font-size: 21px;
            padding: 0 7.8rem 0 3.8rem;
            border: none;
            @media (max-width: $tablet) {
                padding: 0 2rem;
            }

            @media (max-width: 576px) {
                padding: 0 1.5rem;
                line-height: 3.3;
            }
        }

        .custom-placeholder {
            position: absolute;
            top: 0;
            line-height: 5.3;
            left: 3.8rem;
            pointer-events: none;
            font-size: 21px;
            transition: opacity 0.2s ease;
            opacity: 0;
            max-width: calc(100% - 320px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (max-width: $tablet) {
                max-width: calc(100% - 200px);
                left: 2rem;
            }

            @media (max-width: 576px) {
                max-width: calc(100% - 105px);
                font-size: 16px;
                line-height: 4.3;
            }

            &.active {
                opacity: 1; 
                color: #b3b3b3;
            }
        }

        .search-form-actions {
            position: absolute;
            right: 40px;
            top: 0;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media (max-width: $tablet) {
                right: 20px;
            }

            a {
                margin-right: 20px;
            }

            button {
                font-size: 33px;
                position: relative;
                color: #ef5486;

                i {
                    transition: opacity 0.2s ease;

                    &.hover-state {
                        position: absolute;
                        top: 13px;
                        left: 20px;
                        opacity: 0;
                    }
                }

                &:hover {
                    .hover-state {
                        opacity: 1;
                    }

                    .normal-state {
                        opacity: 0;
                    }
                }
                
                @media (max-width: 576px) {
                    font-size: 22px;
                    padding: 5px 10px;
                }
            }
        }
    }
}

@media (max-width: 576px) {
	img {
      width: 100%;
    }
}
