@import '../../assets/sass/abstract/';

.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(245, 245, 245, 0.95);
    flex-direction: column;

    svg {
        width: 80px;
    }
}
