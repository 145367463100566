
.activity-image {
    border-radius: 2px;
    position: relative;
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #151515;
    margin-bottom: 30px;
    min-height: 100px;
    display: flex;
    overflow: hidden;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
}

.date-activity {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    .day-activity {
        color: white;
        font-size: 30px;
    }
    .month-activity {
        color: white;
    }
}

.activity-content {
    .activity-description {
        color: white;
        max-width: 100%;
        white-space: normal;
        word-break: break-word;
    }
    margin-left: 25px;
}

.date-and-endDate {
    color: #808080;
    margin-bottom: 0px;
}

.activity-parent-dates-location-single {
    position: relative;
    top: -21px;
}
