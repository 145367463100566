
.all-art {
    margin-bottom: 50px;
    // margin-bottom: 50px;
	border-top: 1px solid #F5F5F5;
	&:nth-child(2) {
		// border-top: none;
	}
}
img.img-art {
	width: 100%;
    min-height: 180px;
    object-fit: cover;
    height: 125px;
}

.title-name{
    text-align: center;
    font-size: 36px;
    font-family: "HelveticaNowText-Bold";

}

a.title-art {
    font-size: 21px;
}

.media-news{
    margin-top: 50px;
}
  
p.data {
	font-family: 'HelveticaNowText';
	color: #808080;
    margin: 0;
    margin-top: -6px;
    h3.title-art {
        font-family: "HelveticaNowText";
    }
}

h3.title-art {
    font-size: 19px;
}
p.text-art {
    font-family: "HelveticaNowText";
	color: #808080;
    margin-top: 15px;
    font-size: 15px;
}

.button-read-more-last {
    text-align: center;
    margin: 80px 0px;
}

button.read-more-button {
    border: none;
    background-color: white;
    cursor: pointer;
    button.last-read-more {
        cursor: pointer;
        width: 140px;
        height: 40px;
        border: none;
        background-color: white;
        box-shadow: 0 3px 5px 0 rgba(0,0,0,.1);
    }
}

img.img-news-back {
    width: 100%;
    min-height: 180px;
    height: 125px;
    object-fit: cover;
}
@media (max-width: 768px) {
	.media-news {
        flex: 0 0 100%;
        max-width: 100%;
    }
    img.img-news-back {
        height: auto !important;
    }
    img.img-art {
        height: auto;
    }
    
    .calendar-wrapper .calendar {
        width: 50px;
        margin-left: 40px;
        margin-top: -170px;
    }
    .city-and-title {
        margin-top: -35px;
    }
}



