body {
	background: $white;
}

a,
a:hover {
	color: inherit;
}

input,
button,
textarea {

	&:not(#react-select-2-input),
	&:not(.react-tagsinput-input) {
		&:focus {
			box-shadow: 0 0 2px 0 $grey;
			outline: none;
			padding:20;
		}
	}
}

html .react-tagsinput-input {
	width: 140px;
	font-size: 1rem;
	padding: 0px;
}

html {

	.slick-next,
	.slick-prev {
		z-index: 99;
		top: 35%;
	}

	.slick-next {
		right: 0;
	}

	.slick-prev {
		left: 0;
	}

	.css-2b097c-container {
		.css-yk16xz-control {
			border-radius: 0;
			border-width: 1px 1px 1px 1px;
			border-color: $lightGrey;
		}

		&:nth-child(n + 5) .css-yk16xz-control {
			border-top-width: 0;
		}

		&:nth-child(4) .css-yk16xz-control,
		&:nth-child(8) .css-yk16xz-control {
			border-right-width: 0;
		}

		.css-1pahdxg-control {
			border: solid 1px transparent;
			box-shadow: 0 0 2px 0 $grey;
			border-radius: 0;
		}

		input:focus {
			box-shadow: none;
		}
	}

	.css-1okebmr-indicatorSeparator {
		display: none;
	}

	.dropdown-item.active,
	.dropdown-item:active {
		background-color: inherit;
		color: inherit;
	}

	.navbar {
		padding: 1.3rem 3rem;
	}
}

.form-group {
	position: relative;

	.form-control {
		height: 70px;
		text-align: left;
		border-color: $lightGrey;
		padding-left: 55px;
	}

	textarea.form-control {
		text-align: left;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 12px;
	}

	.form-label {
		position: absolute;
		height: 70px;
		left: 0;
		top: 0;
		display: flex;
		align-items: center;
		padding: 0 20px;
		color: $grey;
		pointer-events: none;

		i {
			margin-right: 20px;
			color: $dark;
		}

		span {
			pointer-events: none;

			&.required-field::after {
				content: '*';
				color: $pink;
				margin: 0 5px;
				font-size: 1.5em;
				line-height: 1;
				position: absolute;
				top: 10px;
				left: 35px;
			}
		}
	}

	input {
		color: $dark;

		&:focus {
			border-color: $dark;
		}
	}

	.react-datepicker-wrapper {
		width: 100%;

		input {
			width: 100%;
			height: 70px;
			text-align: left;
			padding: 0.375rem 0.75rem;
			border: 1px solid $lightGrey;
			border-radius: 0.25rem;
			transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
			background-color: #fff;
			font-size: 1rem;
			padding-left: 55px;
		}
	}
}

html .react-datepicker-popper {
	left: auto !important;
	right: 0;
}

html input:not(#react-select-2-input):focus,
html button:not(#react-select-2-input):focus,
html textarea:not(#react-select-2-input):focus,
html .css-5uyihr-control:hover,
html .css-5uyihr-control {
	//box-shadow: none;
	border-color: transparent;
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

html input.react-tagsinput-input:focus,
html .custom-textarea textarea:not(#react-select-2-input):focus,
html input#react-select-2-input {
	box-shadow: none !important;
}

html .css-z939ep-control,
html .react-tagsinput,
html .css-z939ep-control:hover {
	border-color: $lightGrey;
}

.css-15k93dy-Input {
	box-shadow: none !important;
}