@import '../../fonts/HelveticaNowText/styles.scss';

body {
    font-size: 16px;
    line-height: 1.5;
    font-family: 'HelveticaNowText';
    color: $dark;
}

::selection {
    background-color: $dark;
    color: $white;
}

a {
    transition: color 0.2s ease, background-color 0.2s ease;
}

section {
    padding: 60px 0;

    @media (max-width: $tablet) {
        padding: 40px 15px;
        border-bottom: solid 1px $lightGrey;
    }

    &.has-seperator {
        .container {
            position: relative;

            &::after {
                content: '';
                border-bottom: solid 1px $lightGrey;
                position: absolute;
                display: block;
                bottom: -110px;
                left: 0;
                right: 0;
            }

            @media (max-width: $tablet) {
                &::after {
                    content: none;
                }
            }
        }
    }
}

.btn {
    padding: 5px 20px;
    border-radius: 2px;
    background-color: transparent;
    transition: all 0.2s ease;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        box-shadow: none;
    }

    &.btn-light {
        border: solid 1px $lightGrey;
        color: $grey;

        &:hover {
            background-color: $dark;
            color: $white;
        }
    }

    &.btn-menu {
        color: $lightGrey;
    }

    &.no-padding {
        padding: 0;
    }

    &.btn-shadow {
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);

        &:hover {
            box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
        }
    }

    &.btn-faq {
        margin-top: 25px;
        background-color: $pink;
        color: $white;
    }

    &.btn-dark {
        background-color: $dark;
    }

    &.btn-lg {
        padding: 10px 30px;
    }

    &.btn-block {
        width: 100%;
    }

    &.btn-icon {
        padding: 10px;
        font-size: 1.5em;
    }

    &.text-white {
        color: $white;
    }

    &.btn-danger {
        color: $red;
        border-color: $red;

        &:hover {
            background-color: $red;
            color: $white;
        }
    }
}

.title-wrapper {
    margin-bottom: 60px;

    h1.page-title {
        font-weight: bold;
        font-size: 3rem;
        line-height: 1.2;
    }

    h2.page-title {
        font-size: 2.3rem;
        line-height: 1.2;
        font-weight: bold;
    }

    .subtitle {
        font-size: 1.3rem;
        color: $grey;
        font-weight: normal;
    }

    & + & {
        margin-top: 115px;
    }
}

.paper-view {
    border-radius: 0 0 10px 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.bg-grey {
    background-color: $lightGrey;
}

.dropdown-toggle:nth-child(1) {
    border: 0px;
    padding: 20px;
}


.more-filters-parent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.more-filters-text {
    overflow: hidden; 
    text-overflow: ellipsis; 
    width: 230px; 
    float: left; 
    text-align: left; 
    padding-right: 6px; 
    color: #808080;
    margin-right: -6px;
    white-space: nowrap;
}

.more-filters-parent:hover {
    background-color: #f1f1f1;
}

.btn-group-sm {
    pointer-events: none;
    display: none;
}

.cc-4 {
    column-count: 4;
}

.conditional-field {
    position: relative;
    display: none;

    &.active {
        display: block;
    }
}

.form-input-height {
    height: 70px;
    display: flex;
    align-items: center;
}

.custom-file-upload {
    position: relative;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $grey;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $lightGrey;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    // @media (max-width: $tablet) {
    //     padding: 15px;
    // }

    input {
        height: 70px;
        width: 100%;
        position: relative;
        z-index: 2;
        opacity: 0;
        cursor: pointer;

        // @media (max-width: $tablet) {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        // }
    }

    .file-meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0.375rem 20px;

        // @media (max-width: $tablet) {
        //     flex-direction: column;
        //     position: static;
        // }

        label {
            margin: 0;

            & > * {
                margin-right: 20px;

                // @media (max-width: $tablet) {
                //     margin-right: 0;
                //     margin-bottom: 10px;
                //     display: block;
                //     text-align: center;
                // }
            }

            @media (max-width: $tablet) {
                b {
                    display: none;
                }
            }
        }

        .file-preview {
            display: flex;
            align-items: center;

            & > * {
                margin-left: 15px;
            }

            .preview-image {
                width: 60px;
                height: 40px;
                object-fit: cover;
                border-radius: 4px;
            }

            .file-name {
                border: solid 1px $lightGrey;
                padding: 0.438rem 1.5rem;
                border-radius: 4px;
                max-width: 150px;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 40px;
                line-height: 1.6;
                @media (max-width: $tablet) {
                    display: none;
                }
            }

            .input-button {
                display: flex;
                align-items: center;
                top: 15px;
                right: 15px;
                border: none;
                border-radius: 4px;
                padding: 0.438rem 1.5rem;
                background-color: $dark;
                color: $white;
                text-transform: uppercase;
                font-weight: bold;

                // @media (max-width: $tablet) {
                //     margin-left: 0;
                // }
                @media (max-width: $tablet) {
                    font-size: 12px;
                    padding: 5px 10px;
                }
            }
        }
    }
}

.custom-textarea-wrapper {
    border: solid 1px $lightGrey;
    padding: 20px 0;
    border-radius: 4px;

    .form-label {
        position: static;
        height: auto;
    }

    .custom-textarea {
        padding: 30px 30px 0 30px;

        textarea {
            height: 150px;
            resize: none;
            text-align: left;
            padding-right: 30px;
            border: none;

            &::-webkit-scrollbar {
                width: 2px;
            }

            &::-webkit-scrollbar-track {
                background: $white;
            }

            &::-webkit-scrollbar-thumb {
                background: $grey;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
}

.multilang-form {
    position: relative;

    .form-switcher {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;

        @media (max-width: $tablet) {
            position: relative;
            margin-bottom: 30px;
        }

        .custom-language-selector {
            position: relative;
            z-index: 2;
        }
    }
}

.list-capitalize {
    li {
        text-transform: capitalize;
    }
}

.custom-control-input {
    z-index: 1;
    width: 100%;
    top: 6px;
    left: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #151515;
    background-color: #151515;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}
input:not(:placeholder-shown) {
    border-color: green !important;
}
.react-tagsinput {
    display: block;
    width: 100%;
    height: 70px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 3.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid $lightGrey;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.375rem 0.75rem 0.375rem 55px;
    padding-left: 55px !important;

    & > span {
        .react-tagsinput-tag {
            background-color: $lightGrey;
            color: $dark;
            border-color: $lightGrey;
            font-size: 1rem;
        }
    }

    &.react-tagsinput--focused {
        border-color: $lightGrey;
    }
}

.repeater-wrapper {
    padding: 30px 30px 30px 80px;
    border: solid 1px $lightGrey;

    @media (max-width: $tablet) {
        padding: 15px 15px 15px 40px;
    }

    counter-reset: repeater;

    .field-group-wrapper {
        margin-bottom: 40px;
        border-bottom: solid 1px $lightGrey;
        padding-bottom: 24px;
        position: relative;
       
        &::before {
            counter-increment: repeater;
            content: counter(repeater);
            position: absolute;
            top: -30px;
            left: -80px;
            width: 80px;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: $tablet) {
                top: -15px;
                left: -40px;
                width: 40px;
            }
        }
    }
}

@keyframes repeaterAnim {
    from {
        opacity: 0;
        max-height: 0px;
    }

    to {
        opacity: 1;
        max-height: 200px;
    }
}

.blury-content {
    position: relative;

    img {
        width: 100%;

        @media (max-width: $tablet) {
            display: none;
        }
    }

    .blury-content-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 2;
        background-color: #fffaf8;
        border-radius: 5px;
        padding: 10px 80px;
        color: $grey;
        white-space: nowrap;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);

        @media (max-width: $tablet) {
            position: static;
            transform: none;
            white-space: normal;
            margin: 30px 20px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    @media (max-width: $tablet) {
        & + .blury-content {
            display: none;
        }
    }
}

.verification-flex-center {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-none-mobile {
    @media (max-width: $tablet) {
        display: none !important;
    }
}

.remove-field-group {
    position: absolute;
    top: 0;
    right: -19px;
    z-index: 2;
    transition: transform 0.4s ease;
    cursor: pointer;
    @media (max-width: $tablet) {
        top: -18px;
        right: -12px;
    }

    &:hover {
        transform: scale(1.5);
    }
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0.85rem;
    padding-right: 0.85rem;
}

.justify-content-space-between {
    justify-content: space-between;
}

.select-placeholder-parent-no-hover {
    cursor: pointer;
    display: flex;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    height: 100%;
    .placeholderText {
        padding: 20px
    }
}
.select-placeholder-parent {
    cursor: pointer;
    display: flex;
    padding: 0px;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    height: 100%;
    .placeholderText {
        padding: 20px
    }
}
.fas.fa-angle-down.dropdown-icon {
    margin-right: 15px;
}
.search-filter-row {
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    margin-left: 0px;
}


.multi-select-dropdown-menu {
    position: absolute;
    // top: 100%;
    overflow-x: hidden;
    max-height: 250px;
    z-index: 1000;
    display: block;
    float: left;
    // min-width: 16.8rem;
    width: 83%;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;

    .dropdown-parent-data {
        padding: 3px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}

.loading-sppiner-parent {
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .loading-spinner-text { 
        text-align: 'center';
        margin: 12px;
        padding-left: 10px;
    }
}

html body .section-search .btn.btn-clear:focus {
    box-shadow: none !important;
}