
.col-12.blog-item {
    border-top: 1px solid #efefef;
    padding-top: 50px;
    padding-bottom: 50px;
}
.calendar-event-blog {
	text-align: center;	
	p.data-nr-event-blog {
		font-size: 36px;
		margin-bottom: 1px;
		padding: 10px 4px ;
	}	
	p.month-event-blog {
		font-size: 18px;
		margin-bottom: 1px;
		padding: 8px 10px;
	}
}
.box-color-blog-event{
	background-color: #F2F2F2;
}
p.text-title-event-blog {
    font-size: 18px;
}
.city-author-title {
	color: black;
	margin-left: 25px;
	margin-top: 20px;
}
img.event-blog-img {
	width: 100%;
	object-fit: cover;
	height: 118px;
}
@media (max-width: 986px) { 
	img.event-blog-img {
		height: auto !important;
	}
	.calendar-event-blog {
		margin-left: 40px;
		margin-top: -60px;
	}
	p.data-categoris {
		margin-top: 20px;
	}
 }