@import '../../assets/sass/abstract';

// Logo
.modalOpen {
    overflow: hidden;
    height: 100vh;
}

#site-header {
    background-color: $green;

    nav {
        width: 100%;
        li {
            a {
                color: $grey;
            }

            &.active a {
                color: $white;
                font-weight: bold;
            }
        }
    }

    .site-menu {
        color: $white;
        display: flex;
        justify-content: flex-end;
        flex: 1 1 0;
        @media (max-width: $tablet) {
            flex: none;
        }

        & > * {
            margin: 0 0 0 30px;
        }

        .circle-fa-custom::after {
            position: absolute;
            top: 12px;
            right: -20px;
            font-family: "Font Awesome 5 Pro";
            content: "\f111";
            display: inline-block;
            padding-right: 3px;
            vertical-align: middle;
            font-weight: 900;
            font-size: 1px;

            @media (max-width: 576px) {
                display: none;
            }
        }

        & > a {
            line-height: 2;
            display: block;
            position: relative;
            white-space: nowrap;
            i {
                margin-right: 5px;
            }
        }
        button {
            i {
                margin-right: 5px;
            }
        }
        .btn {
            padding-left: 5px;
            padding-right: 5px;
            padding-top: 0;
            padding-bottom: 0;
            font-size: 16px;
            line-height: 2;
            white-space: nowrap;
        }
    }

    .navbar-brand {
        flex: 1 1 0;
        @media (max-width: $tablet) {
            flex: none;
            flex-grow: 1;
        }
        img {
            width: 100px;
            display: block;
        }
    }

    .navbar-collapse {
        @media (max-width: $tablet) {
            flex: 0 1 100%;
            background-color: rgba(58, 173, 73, 0.95);
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            z-index: 2;
            .navbar-nav {
                padding: 80px 0;
                min-height: calc(100vh - 323px);
            }
        }
        .site-menu {
            justify-content: space-around;
            margin-bottom: 80px;
            & > * {
                margin: 0;
                &::after {
                    content: none;
                }
            }
        }
    }

    div.navbar-nav {
        flex: 1 1 0;
        text-align: center;
        justify-content: center;
        @media (min-width: 1400px) {
            font-size: 1.2rem;
        }
    }
    .dropdown {
        &:hover,
        &:focus,
        &:active,
        .dropdown-toggle {
            background-color: transparent;
            box-shadow: none;
        }
        .dropdown-toggle {
            border: 0;
            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
                box-shadow: none;
            }
            &::after {
                content: none;
            }
        }
        .dropdown-menu {
            min-width: 0;
        }
        &.dropdown-right {
            a.profile.dropdown-item {
                display: flex;
                align-items: center;
                .profile-image {
                    margin: 0 10px 0 0;
                    img {
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                        border-width: 5px;
                        border-radius: 50%;
                        border-style: solid;
                        border-color: $highlight;
                    }
                }
            }
            .dropdown-menu {
                left: auto;
                right: 0;
            }
        }
    }

    .login-modal-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1990;
        transition: all 0.4s ease;
    }

    .transitions {
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
        transition: all 0.1s ease;
        z-index: 1999;

        &.active {
            visibility: visible;
            pointer-events: all;
            opacity: 1;
        }

    }

    .login-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 440px;
        z-index: 200000;

        .login-modal-outer {
            background-color: #fff;
            padding: 40px;
        }
        .login-modal-body {
            padding: 40px;
            background-color: #fff;
            min-width: 400px;
            border-radius: 7px;
            @media (max-width: $tablet) {
                min-width: 0;
            }
        }

        .btn {
            margin-bottom: 20px;
        }

        .forgot-password-link {
            font-size: 12px;
        }

        .sign-up {
            font-size: 12px;
            text-align: right;
            margin-bottom: 0;

            .sign-up-button {
                color: $pink;
                cursor: pointer;
            }
        }

        .databaza-logo {
            width: 70px;
            display: block;
            margin: 0 auto;
        }

        .motto-text {
            text-transform: lowercase;
            margin: 1rem 0 2rem;
        }

        .form-label-right {
            position: absolute;
            height: 70px;
            right: 0;
            top: 0;
            display: flex;
            align-items: center;
            padding: 0 20px;
            color: #000;
            cursor: pointer;
        }
    }

    .custom-dropdown {
        .dropdown-toggle.btn.btn-sm {
            font-size: 1rem;
            text-transform: capitalize;
            @media (max-width: $tablet) {
                margin-right: 20px;
            }
        }
        .dropdown-menu {
            background-color: rgba(255, 255, 255, 0.7);
            left: 0;
            top: 26px;
            padding: 0;
            border-radius: 2px;
            .dropdown-item {
                padding: 10px 15px;
                text-align: left;

                .fal {
                    padding-right: 5px;
                }
            }
        }
    }

    .share-links {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        color: $white;
    }

    .btn-primary,
    .btn {
        color: rgba(255, 255, 255, 0.8);
    }

    .navbar-toggler {
        border: 0;
        padding: 0;
        flex: none;
        order: 2;
    }

    .close-lightbox {
        position: absolute;
        top: -23px;
        right: -23px;
        cursor: pointer;

        i {
            font-size: 1em;
        }
    }

    .nav-link {
        color: rgba(255, 255, 255, 0.8);

        &.active {
            font-weight: bold;
        }
    }

    .btn-menu {
        .fal {
            margin-right: 10px;
        }
    }
}
