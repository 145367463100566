
img.post-blog-img {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.wrapper-blogs{
	display: flex;
}
a.text-title-post-blog {
    font-weight: 500;
    font-size: 21px;
    color: black;
}
span.dot-author {
    margin-left: 5px;
}
p.description-blog-post {
	font-size: 15px;
	color: #707070;
	line-height: 2;
	margin: 25px 0px;
}
p.data-categoris {
    margin-bottom: 0rem;
}