@import '../../assets/sass/abstract/';

.pink-background {
    background-color: $pink;
    color: $white;

    .subtitle {
        color: $white;
        padding-bottom: 40px;
        font-size: 1.15rem;
    }
}

.artist-details {
    padding-top: 0;
}

.biography-text {
    .title-wrapper {
        margin: 40px 0 20px;
    }
}

.artist-details-full {
    margin-top: 40px;
    li {
        border-bottom: solid 1px $lightGrey;
        display: flex;
        justify-content: space-between;
        margin: 0;
        min-height: 55px;
        align-items: center;
        color: $white;
        padding: 10px 5px;
        dt {
            font-weight: bold;
        }
        dd {
            margin: 0 0 0 20px;
            max-width: calc(100% - 150px);
            font-weight: normal;
        }
    }
}

.list-col-left ul {
    margin-right: 3rem;
    @media (max-width: $laptop) {
        margin-right: 0;
    }
}

.list-col-right ul {
    margin-left: 3rem;
    @media (max-width: $laptop) {
        margin-left: 0;
        margin-top: -15px;
    }
}


.categoryArtWorks {
    color: #808080
}
.artist-name-artwork {
    color: black
}

.artist-name {
    font-size: 1.2em;
}