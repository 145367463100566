@import '../../assets/sass/abstract/';

.advanced-search {
    position: relative;
    margin: 70px 0;

    form {
        position: relative;
        border: 1px solid $lighterGrey;

        .section-search {
            padding: 0;
            position: relative;

            input {
                box-shadow: none;
                width: 100%;
                line-height: 6.7;
                font-size: 21px;
                padding: 0 124px 0 40px;
                border: none;
            }

            input:focus {
                box-shadow: none !important;
            }
        }

        .section-filter {
            padding: 0;
            /* Grid used by Endrit */
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
            & > * {
                //min-width: 25%;
                /* TODO: use row and col */
                min-width: 33.333333%;
                @media (max-width: $tablet) {
                    min-width: 100%;
                }
            }

            input {
                line-height: 3;
            }

            .css-1hwfws3 {
                padding: 2px 40px;
            }
            .css-1hb7zxy-IndicatorsContainer {
                margin-right: 25px;
            }
        }

        .custom-placeholder {
            position: absolute;
            top: 0;
            line-height: 6.7;
            left: 3.8rem;
            pointer-events: none;
            font-size: 21px;
            transition: opacity 0.2s ease;
            opacity: 0;
            max-width: calc(100% - 320px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 300;

            @media (max-width: $tablet) {
                max-width: calc(100% - 140px);
                left: 2rem;
            }

            &.active {
                opacity: 1;
                color: #b3b3b3;
            }
        }

        .search-form-actions {
            position: absolute;
            right: 40px;
            top: 0;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media (max-width: $tablet) {
                right: 10px;
            }

            a {
                margin-right: 20px;
            }

            button {
                font-size: 26px;
                position: relative;
                color: $pink;

                i {
                    transition: opacity 0.2s ease;

                    &.hover-state {
                        position: absolute;
                        top: 12px;
                        left: 20px;
                        opacity: 0;
                    }
                }

                &:hover {
                    .hover-state {
                        opacity: 1;
                    }

                    .normal-state {
                        opacity: 0;
                    }
                }
            }
        }
    }
}
